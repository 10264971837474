<template>
	<div class="swiperBox" :class="navigation?'naviga':''">
		<div class="swiper_list" :class="'banner_'+jsClass">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="item in list">
					<template v-if="itemType=='partners'">
						<img :src="item.partners_logo" alt="">
						<div class="txt">
							<h4>{{item.partners_name}}</h4>
							<p><template v-for="i in item.content">{{i}}</template></p>
						</div>
					</template>
					<template v-if="itemType=='links'">
						<img :src="item.team_img" alt="">
						<div class="txt">
							<h4>{{item.team_name}}</h4>
							<p><template v-for="i in item.position_title">{{i}}</template></p>
						</div>
					</template>
				</div>
			</div>
			
			<div class="swiper-scrollbar"></div>
		</div>
		<div v-if="pagination" :class="jsClass" class="swiper-pagination"></div>
		<div v-if="navigation" :class="jsClass" class="btn swiper-button-prev acea-row row-center-wrapper">
			<span class="el-icon-arrow-left"></span>
		</div>
		<div v-if="navigation" :class="jsClass" class="btn swiper-button-next acea-row row-center-wrapper">
			<span class="el-icon-arrow-right"></span>
		</div>
	</div>
</template>

<script>
	import Swiper,{Navigation,Pagination,Autoplay} from 'swiper';
	export default {
		props: {
			list:{
				type:Array
			},
			itemType:{
				type:String,
				default:'partners'
			},
			navigation: {
				type: Boolean,
				default: true
			},
			pagination:{
				type:Boolean,
				default:true
			},
			jsClass:{
				type:String,
				default:'js_listA'
			},
			scale:{
				type:Boolean,
				default:true,
			},
			slides:{
				type:Number,
				default:2
			},
			oneItem:{
				type:Boolean,
			}
		},
		mounted() {
			this.openSwiper()
		},
		methods: {
			openSwiper() {
				var swiper = new Swiper('.banner_'+this.jsClass, {
					speed:300,
					autoplay : {
					    delay:5000
					},
					loop: true,
					slidesPerView:this.oneItem?1:3,
					slidesPerGroup: 1,
					centeredSlidesBounds: true,
					spaceBetween: 20,
					modules: [Navigation, Pagination,Autoplay],
					pagination: {
						el: '.swiper-pagination.'+this.jsClass,
						type: 'bullets',
						bulletClass: 'span-look',
					},
					navigation: {
						nextEl: '.swiper-button-next.'+this.jsClass,
						prevEl: '.swiper-button-prev.'+this.jsClass,
						hideOnClick: true
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.naviga{
		padding:0 90px;
	}
	.swiperBox{
		position: relative;
	}
	.btn{
		position: absolute;
		top:50%;
		width:64px;
		height:64px;
		border-radius:50%;
		z-index: 2;
		background:#FFF;
		cursor: pointer;
		color:#333;
		span{
			font-size:24px;
			font-weight: bold;
		}
		&.swiper-button-prev{
			left:0;
		}
		&.swiper-button-next{
			right:0;
		}
		img{
			width:14px;
		}
	}
	.swiper_list{
		overflow: hidden;
		.swiper-slide{
			background:#FFF;
			text-align: center;
			img{
				width:100%;
				vertical-align: middle;
			}
			.txt{
				color:#1D2A55;
				padding:10px 0;
				h4{
					font-size:24px;
					line-height:48px;
					position: relative;
					&::after{
						content:'';display: block;width:30px;height:2px;background:#1D2A55;
						position: absolute;left:50%;bottom:0;transform: translate(-50%,0);
					}
				}
				p{
					font-size:20px;
					padding:0 10px;
					line-height:36px;
				}
			}
		}
	}
	@media screen and(max-width:1400px) {
		.swiper_list{
			.swiper-slide{
				.txt{
					h4{
						font-size:20px;
					}
					p{
						    font-size: 16px;
							line-height:28px;
							margin-top:10px;
					}
				}
			}
		}
	}
	@media screen and(max-width:1200px) {
		.swiper_list{
			.swiper-slide{
				.txt{
					h4{
						font-size:18px;
					}
					p{
						    font-size: 16px;
							line-height:24px;
							margin-top:10px;
					}
				}
			}
		}
	}
	@media screen and(max-width:768px) {
		.naviga{
			padding:0 30px;
		}
		.btn{
			width:48px;
			height:48px;
			background:rgba($color: #FFFFFF, $alpha: 0.8);
			span{
			}
			&.swiper-button-prev{
				left:0;
			}
			&.swiper-button-next{
				right:0;
			}
			img{
				width:14px;
			}
		}
	}
	
</style>
