<template>
  <div class="about">
	<banner :picS="bannerS"></banner>
	<div class="profile">
		<titleOne id="about_1" en='enterprise profile' :title="lang.title.text04"></titleOne>
		<div class="proFileBox">
			<div v-if="indexData.company_profile" class="item acea-row row-between" v-for="(item,index) in indexData.company_profile">
				<img v-if="index % 2==0" class="pic" :src="item.img" alt="">
				<div class="txt">
					<p class="en">{{item.entitle}}</p>
					<h4>{{item.title}}</h4>
					<div class="cont">
						{{item.content}}
					</div>
				</div>
				<img v-if="index % 2>0" class="pic" :src="item.img" alt="">
			</div>
		</div>
	</div>
	
	<!-- 企业荣誉 -->
	<div class="honor">
		<titleOne id="about_2" en='Enterprise honor' :title="lang.title.text04"></titleOne>
		<div class="honorBox">
			<bannerA itemType="honor" jsClass='js_honor' :list="indexData.enterprise_honor" :scale="false" :slides="mobile?2:5" :navigation="false"></bannerA>
		</div>
	</div>
	
	<!-- 团队荣誉 -->
	<div class="team">
		<titleOne id="about_3" en='team introduction' :title="lang.title.text05"></titleOne>
		<div class="teamBox" v-if="mobile">
			<bannerA itemType="team" :list="indexData.team_introduction" :scale="false" :slides="2" jsClass='js_team' :navigation="false"></bannerA>
		</div>
		<div class="teamBox" v-else>
			<bannerA itemType="team" :list="indexData.team_introduction" jsClass='js_team'></bannerA>
		</div>
	</div>
	
	<div class="Links">
		<div class="widthBox">
			<div class="linksBox">
				<swiperList v-if="mobile" jsClass='js_links' :list="indexData.team_introduction_zc" itemType="links" :pagination="false" oneItem :slides="1"></swiperList>
				<swiperList v-else jsClass='js_links' :list="indexData.team_introduction_zc" itemType="links" :pagination="false" :slides="4"></swiperList>
				<div class="botBox">
					<router-link class="more color" to="/team">{{lang.public.more}}
						<img class="icon" src="@/assets/icon_04.png" alt="">
					</router-link>
				</div>
			</div>
			
		</div>
	</div>
	
	<div class="partners">
		<titleOne id="about_4" en='partners' :title="lang.title.text06"></titleOne>
		<div class="widthBox">
			<div class="partnersBox">
				<swiperList v-if="mobile" :list="indexData.cooperative_partner" itemType="partners" jsClass='js_listB' oneItem :pagination="false" :slides="1"></swiperList>
				<swiperList v-else :list="indexData.cooperative_partner" itemType="partners" jsClass='js_listB' :pagination="false" :slides="4"></swiperList>
				<div class="botBox">
					<router-link class="more" to="/partners">{{lang.public.more}}
						<img class="icon" src="@/assets/icon_03.png" alt="">
					</router-link>
				</div>
			</div>
		</div>
	</div>
	
	
	<div class="contact" v-if="indexData.contact_us" v-cloak>
		<titleOne id="about_6" en='Contact us' :title="lang.title.text07"></titleOne>
		<div class="widthBox contactBox hidden-xs-only">
			<h4>{{lang.public.text13}}</h4>
			<div class="item">
				<span class="iconfont icon-dianhuatianchong"></span>
				<div class="cont">
					<div class="name">{{lang.about.text01}}:</div>
					<div class="tel">{{indexData.contact_us.phone}}</div>
				</div>
			</div>
			<div class="item">
				<span class="iconfont icon-wangluo"></span>
				<div class="cont">
					<div class="name">{{lang.about.text02}}:</div>
					<div class="tel">{{indexData.contact_us.web_url}}</div>
				</div>
			</div>
			<h4 class="mat100">{{lang.about.text03}}</h4>
			<div class="item">
				<span class="iconfont icon-ditu-dibiao"></span>
				<div class="cont">
					<div class="name">{{lang.about.text04}}:</div>
					<div class="tel">{{indexData.contact_us.address}}</div>
				</div>
			</div>
		</div>
		<baidu-map class="map" ak="iItrfVQKT5urUdfSGmSEtw0aCVAj98iw" :zoom="15" :center="center">
			<bm-marker :position="{lng: center.lng, lat: center.lat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"></bm-marker>
		</baidu-map>
		<div class="m_contact_box hidden-sm-and-up">
			<h4>{{lang.public.text13}}</h4>
			<div class="item">
				<span class="iconfont icon-dianhuatianchong"></span>
				<div class="cont">
					<div class="name">{{lang.about.text01}}:</div>
					<div class="tel">{{indexData.contact_us.phone}}</div>
				</div>
			</div>
			<div class="item">
				<span class="iconfont icon-wangluo"></span>
				<div class="cont">
					<div class="name">{{lang.about.text02}}:</div>
					<div class="tel">{{indexData.contact_us.web_url}}</div>
				</div>
			</div>
		</div>
	</div>
	
  </div>
</template>
<script>
	import titleOne from '@/components/titleOne.vue'
	import banner from '@/components/banner.vue'
	import bannerA from '@/components/bannerA.vue'
	import swiperList from '@/components/swiper_list.vue'
	import recruitBox from '@/components/recruit.vue'
	export default {
		name: 'About',
		components:{
			titleOne,
			banner,
			bannerA,
			swiperList,
			recruitBox
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
			lang() {
				return this.$store.state.lang
			},
		},
		data:function() {
			return {
				indexData:{},
				center:{lng: 0, lat: 0},
				mobile:false
			};
		},
		created() {
			var width = document.body.clientWidth
			if(width <= 768){
				this.mobile = true
			}
			this.$api.get('home/index/enterpriseIntroduction',{}).then(res=>{
				this.indexData = res.data
				this.center.lng = res.data.contact_us.lng
				this.center.lat = res.data.contact_us.lat
			})
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		methods:{
		}
	}
</script>
<style lang="scss">
	$paddingTop:60px;
	.map{
	    width: 100%;
	    height: 300px;
		.BMap_cpyCtrl {
		  display: none !important;
		}
		.anchorBL {
		  display: none !important;
		}
	  }
	.about{
		.profile{
			padding-top:$paddingTop;
			.proFileBox{
				.item{
					margin-top:40px;
					
					.pic{
						width:calc(50% - 20px);
					}
					.txt{
						width:50%;
						.en{
							margin-top:70px;
							font-size:50px;
							color:rgba($color: #1D2A55, $alpha: 0.35);
						}
						h4{
							font-size:48px;
							color:#1D2A55;
						}
						.cont{
							margin-top:60px;
							padding:20px 30px;
							background:rgba($color: #1D2A55, $alpha: 0.05);
							font-size:24px;
							line-height:150%;
							height:450px;
							color:#1D2A55;
							padding-right:25%;
							overflow-y: auto;
						}
					}
					&:nth-child(2n){
						.en{
							text-align: right;
						}
						h4{
							text-align: right;
						}
						.cont{
							padding-right:30px;
							padding-left:25%;
						}
					}
				}
			}
		}
		
		.honor{
			padding-top:$paddingTop;
			border-top:1px solid #1D2A55;
			margin-top:50px;
			.honorBox{
				padding:50px 0 40px;
			}
		}
		
		.team{
			padding-top:$paddingTop;
			border-top:1px solid #1D2A55;
			margin-top:50px;
			.teamBox{
				padding:90px 0 60px;
			}
		}
		
		.Links{
			background:rgba($color: #1D2A55, $alpha: 1.0);
			.linksBox{
				overflow: hidden;
				padding:100px 0 80px;
			}
			
		}
		
		.partners{
			padding-top:$paddingTop;
			position: relative;
			&::after{
				content:'';display: block;width:100%;height:50%;
				position: absolute;
				background:#FFF;
				left:0;top:0;
				z-index: -1;
			}
			&::before{
				content:'';display: block;width:100%;height:50%;
				background:rgba($color: #1D2A55, $alpha: 0.05);
				position: absolute;
				left:0;bottom:0;
				z-index: -1;
			}
			.partnersBox{
				padding:90px 0 60px;
			}
			
			
		}
		.botBox{
			text-align: center;
			margin-top:50px;
			.more{
				display:inline-block;
				width:160px;
				height:50px;
				background:#1D2A55;
				color:#FFF;
				text-align: center;
				line-height:50px;
				font-size:18px;
				position: relative;
				&.color{
					border:1px solid #FFF;
				}
				.icon{
					position: absolute;
					top:50%;
					right:-54px;
					width:77px;
					transform: translate(0,-50%);
				}
			}
		}
		.recruitment{
			padding-top:$paddingTop;
		}
	
		.contact{
			padding-top:$paddingTop;
			border-top:1px solid #1D2A55;
			margin-top:50px;
			.contactBox{
				h4{
					font-size:36px;
					color:#1D2A55;
					margin-top:50px;
					&.mat100{
						margin-top:100px;
					}
				}
				.item{
					margin-top:40px;
					.iconfont{
						font-size:36px;
						font-weight: bold;
						color:#1D2A55;
						float:left;
					}
					img{
						width:36px;
						height:36px;
						float:left;
					}
					.cont{
						margin-left:86px;
						font-size:24px;
						line-height:40px;
						&>div{
							font-size:24px;
							line-height:40px;
						}
					}
				}
			}
			.map{
				width:100%;
				margin-top:30px;
			}
		}
	}
	
	@media screen and(max-width:1400px) {
		.about{
			.profile{
				.proFileBox{
					.item{
						.txt{
							.en{
								font-size:40px;
							}
							h4{
								font-size:36px;
							}
							.cont{
								font-size:18px;
								height:350px;
							}
						}
					}
				}
			}
			.recruitment{
			}
			.contact{
				.contactBox{
					h4{
						font-size:32px;
					}
					.item{
						img{
							width:32px;
							height:32px;
						}
						.cont{
							font-size:20px;
							.name{
								font-size:20px;
							}
							.tel{
								font-size:20px;
							}
						}
					}
				}
			}
		}
	}
	@media screen and(max-width:1200px) {
		.about{
			.profile{
				.proFileBox{
					.item{
						.txt{
							.en{
								font-size:36px;
								margin-top:50px;
							}
							h4{
								font-size:32px;
							}
							.cont{
								font-size:16px;
								height:300px;
								margin-top:40px;
							}
						}
					}
				}
			}
			.recruitment{
			}
			.contact{
				.contactBox{
					h4{
						font-size:28px;
					}
					.item{
						img{
							width:28px;
							height:28px;
						}
						.cont{
							font-size:18px;
							.name{
								font-size:18px;
							}
							.tel{
								font-size:18px;
							}
						}
					}
				}
			}
		}
	}
	
	@media screen and(max-width:768px) {
		.about{
			.profile{
				padding-top:15px;
				.proFileBox{
					.item{
						margin-top:20px;
						.pic{
							max-height:240px;
						}
						.txt{
							.en{
								font-size:12px;
								line-height:20px;
								margin-top:10px;
							}
							h4{
								font-size:16px;
								line-height:20px;
							}
							.cont{
								margin-top:10px;
								height:100px;
								overflow-y: auto;
								padding:10px 15px;
							}
						}
					}
				}
			}
			
			.honor{
				margin-top:20px;
				padding-top:20px;
				.honorBox{
					padding:20px;
				}
			}
			.team{
				margin-top:20px;
				padding-top:20px;
				.teamBox{
					padding:20px 20px;
				}
			}
			.Links{
				.linksBox{
					padding:20px 0 20px;
				}
			}
			.botBox{
				margin-top:20px;
			}
			.partners{
				padding-top:20px;
				.partnersBox{
					padding:30px 0 20px;
				}
			}
			.contact{
				padding:20px 20px 0;
				margin-top:20px;
				.map{
					height:200px;
				}
				.m_contact_box{
					margin-top:20px;
					background: #ffffff;
					border-radius: 6px;
					box-shadow: 0px 0px 13px 2px rgba(29,42,85,0.10); 
					box-sizing: border-box;
					padding:24px 30px;
					h4{
						font-size:14px;
						line-height:20px;
						color:#1D2A55;
					}
					.item{
						margin-top:16px;
						.iconfont{
							float:left;
							font-size:16px;
							line-height:20px;
						}
						.cont{
							margin-left:32px;
							font-size:12px;
							line-height:20px;
							color:#1D2A55;
						}
					}
				}
			}
		}
	}
</style>